import { createContext, useState } from "react";

export const RapportBContextProvider = ({ children }) => {
    const [rapportData, setRapportData] = useState({
        warehouse: { label: "Tout", value: "all" },
        startDate: new Date(),
    });

    return <RapportBContext.Provider value={{ rapportData, setRapportData }}>{children}</RapportBContext.Provider>;
};

export const RapportBContext = createContext();
