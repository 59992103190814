import React, { useEffect, useState, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router";
import { PrivateRoute } from "./RouteType";
import { UserContext } from "../context/userContext";
import { axiosGet } from "../api/API";
import { SpinnerLoading, Toast } from "@sobrus-com/sobrus-design-system";
import { Font } from "@react-pdf/renderer";
import { Review } from "@sobrus-com/sobrus-surveys-package";
import { API_URL2 } from "env";
import "../styles/fidny.scss";
import Nav from "../pages/Nav";
import TableauDeBord from "../pages/dashboard/TableauDeBord";
import Signin from "../pages/Signin";
import poppinsRegular from "assets/fonts/Poppins-Regular.ttf";
import poppinsItalic from "assets/fonts/Poppins-Italic.ttf";
import poppinsLight from "assets/fonts/Poppins-Light.ttf";
import poppinsLightItalic from "assets/fonts/Poppins-LightItalic.ttf";
import poppinsMedium from "assets/fonts/Poppins-Medium.ttf";
import poppinsMediumItalic from "assets/fonts/Poppins-MediumItalic.ttf";
import poppinsSemiBold from "assets/fonts/Poppins-SemiBold.ttf";
import poppinsSemiBoldItalic from "assets/fonts/Poppins-SemiBoldItalic.ttf";
import poppinsBold from "assets/fonts/Poppins-Bold.ttf";
import poppinsBoldItalic from "assets/fonts/Poppins-BoldItalic.ttf";
import Page404 from "pages/Page404";
import Settings from "pages/settings/Settings";
import Profile from "pages/settings/Profile";
import OrdersArchive from "pages/orders/OrdersArchive";

// const TableauDeBord = lazy(() => import("../pages/dashboard/TableauDeBord"));
const Fournisseurs = lazy(() => import("../pages/fournisseurs/Fournisseurs"));
const BonDeCommande = lazy(() => import("../pages/orders/BonDeCommande"));
const EditPrices = lazy(() => import("pages/orders/groupedOrder/EditPrices"));
const Products = lazy(() => import("../pages/products/Products"));
const ProductsHistory = lazy(() => import("pages/products/ProductsHistory"));
const ProductDetail = lazy(() => import("../pages/products/ProductDetail"));
const ProductsHistoryDetails = lazy(() => import("pages/products/ProductsHistoryDetails"));
const SuggestProduct = lazy(() => import("../pages/products/SuggestProduct"));
const FourniDetail = lazy(() => import("../pages/fournisseurs/FourniDetail"));
const SuggestFourni = lazy(() => import("../pages/fournisseurs/SuggestFourni"));
const SuppliersHistory = lazy(() => import("pages/fournisseurs/SuppliersHistory"));
const SuppliersHistoryDetails = lazy(() => import("pages/fournisseurs/SuppliersHistoryDetails"));
const Events = lazy(() => import("../pages/events/Events"));
const EventsDetail = lazy(() => import("../pages/events/EventsDetail"));
const Groupes = lazy(() => import("../pages/groups/Groupes"));
const NewGroup = lazy(() => import("../pages/groups/NewGroup"));
const GroupDetail = lazy(() => import("../pages/groups/GroupDetail"));
const NewEvent = lazy(() => import("../pages/events/NewEvent"));
const Offers = lazy(() => import("../pages/offers/Offers"));
const OfferCreation = lazy(() => import("pages/offers/internalOffers/offerAdd/OfferCreation"));
const OrderAdd = lazy(() => import("../pages/offers/orderAdd/OrderAdd"));
const OrderAddStep1 = lazy(() => import("../pages/offers/orderAdd/OrderAddStep1"));
const OffersDetailStep2 = lazy(() => import("../pages/offers/OffersDetailStep2"));
const OffersDetailStep3 = lazy(() => import("../pages/offers/OffersDetailStep3"));
const Contacts = lazy(() => import("../pages/contacts/Contacts"));
const ContactDetails = lazy(() => import("../pages/contacts/ContactDetails"));
const ContactCreation = lazy(() => import("../pages/contacts/ContactCreation"));
const OrderDetails = lazy(() => import("../pages/orders/OrderDetails"));
// const RepBalanceDetails = lazy(() => import("../pages/report/RepBalanceDetails"));
const ReportBalance = lazy(() => import("../pages/report/ReportBalance"));
const Consumed = lazy(() => import("pages/report/Consumed"));
const Supported = lazy(() => import("pages/report/Supported"));
// import NewMembre from "../pages/groups/NewMembre";

Font.register({
    family: "Poppins",
    fonts: [
        { src: poppinsRegular }, // font-style: normal, font-weight: normal
        { src: poppinsItalic, fontStyle: "italic" },
        { src: poppinsLight, fontStyle: "normal", fontWeight: 300 },
        { src: poppinsLightItalic, fontStyle: "italic", fontWeight: 300 },
        { src: poppinsMedium, fontStyle: "normal", fontWeight: 500 },
        { src: poppinsMediumItalic, fontStyle: "italic", fontWeight: 500 },
        { src: poppinsSemiBold, fontStyle: "normal", fontWeight: 600 },
        { src: poppinsSemiBoldItalic, fontStyle: "italic", fontWeight: 600 },
        { src: poppinsBold, fontStyle: "normal", fontWeight: 700 },
        { src: poppinsBoldItalic, fontStyle: "italic", fontWeight: 700 },
    ],
});

const HomeNavigator = () => {
    const [isLogin, setIsLogin] = useState(false);
    const [isFetching, setisFetching] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        setIsLogin(false);
        axiosGet("users/authenticated-user").then((res) => {
            if (res?.data) {
                setIsLogin(true);
                setUser(res.data);
            }
            setisFetching(false);
        });
    }, []);

    console.log(user);

    if (!isFetching) {
        return (
            <div className="fidny">
                <Toast />
                {isLogin && <Nav user={user} />}
                {isLogin && <Review baseUrl={API_URL2} backgroundColor="#1bb9ac" />}
                {/* <TableauDeBord /> */}
                <UserContext.Provider value={{ isLogin, user }}>
                    <Routes>
                        <Route path="/" element={<PrivateRoute component={<TableauDeBord />} acl=" " />} />
                        <Route
                            path="/produits"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <Products />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/produits/produits-historique"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <ProductsHistory />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/produits/produits-historique/details/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <ProductsHistoryDetails />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/produits/suggerer-un-produit"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <SuggestProduct />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/produits/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <ProductDetail />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        {/* Fournisseurs */}
                        <Route
                            path="/fournisseurs"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <Fournisseurs />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/fournisseurs/suggerer-un-fournisseur"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <SuggestFourni />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/fournisseurs/suggerer-un-fournisseur/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <SuggestFourni />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/fournisseurs/historique"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <SuppliersHistory />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/fournisseurs/historique/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <SuppliersHistoryDetails />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/fournisseurs/details/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <FourniDetail />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        {/* Contacts */}
                        <Route
                            path="/contacts"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <Contacts />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/contacts/details/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <ContactDetails />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/contacts/contact-creation"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <ContactCreation />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/contacts/contact-creation/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <ContactCreation />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        {/* Orders */}
                        <Route
                            path="/bon-de-commande"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <BonDeCommande />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/bon-de-commande/archive"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <OrdersArchive />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/bon-de-commande/details/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <OrderDetails />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/bon-de-commande/modifier-prix/:orderId/:offerId"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <EditPrices />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        {/* <Route path="/bon-de-commandes/pdf" element={<PrivateRoute component={<PDFGenerator />} />} />
                        <Route path="/bon-de-commandes/pdf2" element={<PrivateRoute component={<PDFGenerator2 />} />} /> */}
                        {/* Offers */}
                        <Route
                            path="/offres"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <Offers />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/offres/creation-offre"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <OfferCreation />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/offres/creation-offre/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <OfferCreation />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/offres/details/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <OrderAdd />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        >
                            <Route index element={<Navigate to="/offres/details/:id/step1" />} />
                            <Route
                                path="/offres/details/:id/step1"
                                element={
                                    <PrivateRoute
                                        component={
                                            <Suspense fallback={<Loader />}>
                                                <OrderAddStep1 />
                                            </Suspense>
                                        }
                                        acl=" "
                                    />
                                }
                            />
                            <Route
                                path="/offres/details/:id/step1/:orderId"
                                element={
                                    <PrivateRoute
                                        component={
                                            <Suspense fallback={<Loader />}>
                                                <OrderAddStep1 />
                                            </Suspense>
                                        }
                                        acl=" "
                                    />
                                }
                            />
                            <Route
                                path="/offres/details/:id/step2"
                                element={
                                    <PrivateRoute
                                        component={
                                            <Suspense fallback={<Loader />}>
                                                <OffersDetailStep2 />
                                            </Suspense>
                                        }
                                        acl=" "
                                    />
                                }
                            />
                            <Route
                                path="/offres/details/:id/step3"
                                element={
                                    <PrivateRoute
                                        component={
                                            <Suspense fallback={<Loader />}>
                                                <OffersDetailStep3 />
                                            </Suspense>
                                        }
                                        acl=" "
                                    />
                                }
                            />
                        </Route>
                        {/* Events */}
                        <Route
                            path="/evenements"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <Events />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/evenements/nouvel-evenement"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <NewEvent />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/evenements/nouvel-evenement/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <NewEvent />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/evenements/details/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <EventsDetail />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/groupes"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <Groupes />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/groupes/nouveau-group"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <NewGroup />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/groupes/nouveau-group/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <NewGroup />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        {/* <Route path="/groupes/nouveau-membre" element={<PrivateRoute component={<NewMembre />} />} /> */}
                        <Route
                            path="/groupes/details/:id"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <GroupDetail />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        {/* Reports */}
                        <Route
                            path="/rapport-balance"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <ReportBalance />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/rapport-balance/consomme/:groupId/:memberId/:year/:wharehouse"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <Consumed />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        <Route
                            path="/rapport-balance/supporte/:groupId/:memberId/:year/:wharehouse"
                            element={
                                <PrivateRoute
                                    component={
                                        <Suspense fallback={<Loader />}>
                                            <Supported />
                                        </Suspense>
                                    }
                                    acl=" "
                                />
                            }
                        />
                        {/* <Route
                            path="/rapport-balance/details/:id"
                            element={
                                <Suspense fallback={<Loader />}>
                                    <RepBalanceDetails />
                                </Suspense>
                            }
                            acl=" "
                        /> */}
                        {/* Settings */}
                        <Route path="/parametres" element={<PrivateRoute component={<Settings />} acl={" "} />}>
                            <Route index element={<Profile />} />
                            <Route path="/parametres/profil" element={<Profile />} />
                        </Route>
                        <Route path="/signin" element={<Signin />} acl=" " />
                        <Route path="/page404" element={<Page404 />} acl=" " />
                        <Route path="/auth/success" element={<Navigate to="/" />} acl=" " />
                        <Route path="*" element={<Navigate to="/page404" />} />
                    </Routes>
                </UserContext.Provider>
            </div>
        );
    } else
        return (
            <div className="fidny" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <SpinnerLoading color="#1bb9ac" loading={true} />
            </div>
        );
};

function Loader() {
    return (
        <div className="fidny">
            <div className="loader">
                <SpinnerLoading color="#1bb9ac" loading />
            </div>
        </div>
    );
}

export default HomeNavigator;
