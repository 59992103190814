let AUTH_CLIENT_ID = "10061";
let AUTH_REDIRECT_URL = "https://api.fidny.sobrus.ovh/auth/token";
let AUTH_DOMAIN = "https://account.sobrus.ovh";

let API_URL = "https://api.fidny.sobrus.ovh/api/";
let API_URL2 = "https://api.fidny.sobrus.ovh";

let EVENT_IMG = "https://api.events.sobrus.ovh/images/events/";
let OFFER_IMG = "https://api.marketplace.sobrus.ovh/uploaded_files/offers/images/";
let PRODUCT_IMG_PATH = "https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/";
let CDN_URL = "https://cdn.sobrus.ovh/";

if (process.env.REACT_APP_ENV === "production") {
    AUTH_CLIENT_ID = "10130";
    AUTH_REDIRECT_URL = "https://api.groupsv2.sobrus.com/auth/token";
    AUTH_DOMAIN = "https://account.sobrus.com";
    API_URL = "https://api.groupsv2.sobrus.com/api/";
    API_URL2 = "https://api.groupsv2.sobrus.com";
    EVENT_IMG = "https://api.events.sobrus.com/images/events/";
    OFFER_IMG = "https://api.marketplace.sobrus.com/uploaded_files/offers/images/";
    PRODUCT_IMG_PATH = "https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/";
    CDN_URL = "https://sobruscdn.com/";
}

export const AUTH_URL = `${AUTH_DOMAIN}/authorize?response_type=code&client_id=${AUTH_CLIENT_ID}&scope=openid%20offline_access%20profile%20email&redirect_uri=${AUTH_REDIRECT_URL}&prompt=login`;

export { API_URL, API_URL2, EVENT_IMG, OFFER_IMG, PRODUCT_IMG_PATH, CDN_URL };
