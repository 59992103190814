import { useState } from "react";

const usePagination = (setFetching, pageNum) => {
    const [pageNumber, setPageNumber] = useState(pageNum || 1);

    const pageChange = (dir) => {
        if (dir === "prev" && pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            setFetching(true);
        } else {
            setPageNumber(pageNumber + 1);
            setFetching(true);
        }
    };
    return { pageNumber, pageChange, setPageNumber };
};

export default usePagination;
