import { CustomButton, StatisticalCard } from "@sobrus-com/sobrus-design-system";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { API_URL2 } from "env";
import { Posts } from "@sobrus-com/sobrus-posts-package";
import { UserContext } from "context/userContext";
import BackwardTitle from "components/backwardTitle/BackwardTitle";
import { axiosGet } from "api/API";

const TableauDeBord = () => {
    // const [posts, setPosts] = useState(null);
    const [stats, setStats] = useState(null);
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    // useEffect(() => {
    //     if (!posts) {
    //         axiosGet("posts").then((res) => {
    //             setPosts(res?.data?.posts);
    //             console.log("posts", res);
    //         });
    //     }
    // }, []);

    useEffect(() => {
        if (!stats) {
            axiosGet("dashboard").then((res) => {
                setStats(res?.data);
            });
        }
    }, []);

    const postUserData = {
        fullName: `${user?.lastName} ${user?.firstName}`,
        userId: user?.id,
    };

    return (
        <section className="dashbord-container fd-container">
            <div className="fd-header">
                <BackwardTitle title="Fil d'actualités" subTitle="Tableau de bord" />
            </div>
            <div className="dashbord-body">
                <div className="dashb-actions">
                    <div className="dashb-actions-header">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48">
                            <g id="action" transform="translate(-14 -11.999)">
                                <rect
                                    id="Rectangle_7"
                                    data-name="Rectangle 7"
                                    width="48"
                                    height="48"
                                    rx="13"
                                    transform="translate(14 11.999)"
                                    fill="rgba(85,172,237,0.08)"
                                />
                                <path
                                    id="shortcut-script-app"
                                    d="M29.779,21.242l-3.5-1.578,3.5-1.578a1.824,1.824,0,0,0,0-3.295L21.025,10.84l2.28-2.481a2,2,0,0,0,0-2.693L18.424.38a1.222,1.222,0,0,0-2.1.827l0,2.991a11.779,11.779,0,0,0-5.519,1.812.449.449,0,0,0,.5.743,11.153,11.153,0,0,1,5.468-1.679.448.448,0,0,0,.447-.449l0-3.42a.317.317,0,0,1,.547-.218l4.878,5.285a1.1,1.1,0,0,1,0,1.478l-4.866,5.3a.352.352,0,0,1-.584-.217c0-.227,0-3.458,0-3.595a.448.448,0,0,0-.467-.443,7.058,7.058,0,0,0-2.489.66,5.246,5.246,0,0,0-3.048,4.1,6.949,6.949,0,0,0,1.876,5.92,6,6,0,0,1-3.245-1.893,6.618,6.618,0,0,1-1.679-4.352A7.846,7.846,0,0,1,9.67,8.268a.449.449,0,0,0-.707-.552A8.285,8.285,0,0,0,7.3,12.08L1.3,14.792a1.824,1.824,0,0,0,0,3.295l3.5,1.578L1.3,21.242a1.824,1.824,0,0,0,0,3.295l12.672,5.72a3.819,3.819,0,0,0,3.141,0l5.245-2.368a.449.449,0,0,0-.369-.817l-5.245,2.368a2.92,2.92,0,0,1-2.4,0L1.664,23.72a.922.922,0,0,1,0-1.66L5.779,20.2a.449.449,0,0,0,.087-.052l8.1,3.657a3.819,3.819,0,0,0,3.141,0L25.2,20.155a.453.453,0,0,0,.047.025l4.165,1.88a.922.922,0,0,1,0,1.66l-4.872,2.2a.449.449,0,0,0,.369.817l4.872-2.2a1.824,1.824,0,0,0,0-3.295ZM16.739,22.99a2.92,2.92,0,0,1-2.4,0L1.664,17.269a.922.922,0,0,1,0-1.66l5.583-2.521a7.552,7.552,0,0,0,1.9,5.086,7.029,7.029,0,0,0,5.316,2.335.451.451,0,0,0,.231-.832,6.088,6.088,0,0,1-2.624-5.992,4.328,4.328,0,0,1,2.521-3.41A6.367,6.367,0,0,1,16.3,9.744c.01.945.031,2.918.031,3.088a1.229,1.229,0,0,0,2.1.823l1.948-2.12,9.024,4.074a.922.922,0,0,1,0,1.66Z"
                                    transform="translate(21.805 19.861)"
                                    fill="#55aced"
                                />
                            </g>
                        </svg>

                        <span>Actions rapides</span>
                    </div>
                    <div className="dashb-actions-body">
                        <CustomButton
                            color="warning"
                            icon={<img src={require("../../assets/drugs.svg").default} alt="" />}
                            label="Suggérer un produit"
                            onClick={() => navigate("/produits/suggerer-un-produit")}
                        />
                        <CustomButton
                            color="info"
                            icon={<img src={require("../../assets/fourni.svg").default} alt="" />}
                            label="Suggérer un fournisseur"
                            onClick={() => navigate("/fournisseurs/suggerer-un-fournisseur")}
                        />
                        {/* <CustomButton
                            color="primary"
                            icon={<img src={require("../../assets/invoice.svg").default} alt="" />}
                            label="Créer une bon de commande"
                            onClick={function noRefCheck() {}}
                        /> */}
                        <CustomButton
                            color="danger"
                            icon={<img src={require("../../assets/appointment.svg").default} alt="" />}
                            label="Créer un événement"
                            onClick={() => navigate("/evenements/nouvel-evenement")}
                        />
                    </div>
                </div>
                <div className="dashboard-posts">
                    {/* {posts &&
                        posts?.length > 0 &&
                        posts?.map((post) => (
                            <PostCard key={post.id}>
                                <CardHeader
                                    Date={format(parseISO(post.createdAt), "dd/MM/yy à HH:mm:ss", Date)}
                                    docName="Dr Salim"
                                    image={require("../../assets/doctor-icon.svg").default}
                                    showStatus
                                    status="Nouveau"
                                    statusStyle={{}}
                                />
                                <DashboardCardBody
                                    description="Sobrus Pharma vous souhaite une joyeuse fête de la jeunesse, toute la jeunesse est à l’honneur aujourd’hui. Nous serons à votre disposition à partir du Samedi 22 Août."
                                    imgOnclick={() => window.open(post.imageLink, "_blank")}
                                    imgResizeMode="contain"
                                    isImage
                                    source={post.image}
                                    title={post.title}
                                />
                                <CardLikeAndComment commentNumber="0" isLiked likesNumber="0" />
                            </PostCard>
                        ))}
                    {(!posts || posts?.length < 1) && (
                        <PostCard style={{ height: "400px" }}>
                            <CardHeader
                                // Date="2020-08-20 19:55:10"
                                docName="Dr Salim"
                                image={require("../../assets/doctor-icon.svg").default}
                                // showStatus
                                status="Nouveau"
                                statusStyle={{}}
                            />
                            <SpinnerLoading loading={true} />
                        </PostCard>
                    )} */}
                    <Posts user={postUserData} url={API_URL2} />
                </div>
                <div className="dashb-statistics">
                    <StatisticalCard
                        color="warning"
                        desc="Bons de commandes ouverts"
                        number={stats?.totalOrders || 0}
                    />
                    <StatisticalCard
                        color="info"
                        desc="Produits jusqu'à aujourd'hui"
                        number={stats?.totalProducts || 0}
                    />
                    <StatisticalCard desc="Fournisseurs jusqu'à aujourd'hui" number={stats?.totalSuppliers || 0} />
                </div>
            </div>
        </section>
    );
};

export default TableauDeBord;
