import {
    AsyncSelect,
    Button,
    DatePicker,
    EmptyData,
    Input,
    InputSelect,
    LinearProgress,
    Table,
    TableHeader,
    TableHeaderActions,
    TableHeaderTitle,
    TableLoader,
    TableNextPage,
    TablePage,
    TablePreviousPage,
    TableRefreshBtn,
    TableSearch,
    TableSearchBtn,
    TableSearchItem,
    TableSearchRow,
    Tag,
    Th,
} from "@sobrus-com/sobrus-design-system";
import BackwardTitle from "components/backwardTitle/BackwardTitle";
import { DataContext } from "context/dataContext";
import { format, parseISO } from "date-fns";
import isEqual from "lodash.isequal";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosGet } from "../../api/API";
import { useLocalStorage } from "../../utils/useLocalStorage";
import usePagination from "../../utils/usePagination";

const OrdersArchive = () => {
    const { tableData, setTableData } = useContext(DataContext);
    const [isFetchingOrders, setIsFetchingOrders] = useState(tableData?.ordersArchive?.data === undefined);
    const [suppliers, setSuppliers] = useState(null);
    const [users, setUsers] = useState(null);
    const [supplierInput, setSupplierInput] = useLocalStorage("archivesupplierOrder", "");
    const [typeInput, setTypeInput] = useLocalStorage("archivetypeOrder", "");
    const [gestInput, setGestInput] = useLocalStorage("archivesupplierOrder", "");
    const [nomInput, setNomInput] = useLocalStorage("archivenomContact", "");
    const [objetInput, setObjetInput] = useLocalStorage("archiveobjetOrder", "");
    const [deadline, setDeadline] = useLocalStorage("archivedeadlineOrder", "");
    const [updateDate, setUpdateDate] = useLocalStorage("archiveupdateDateOrder", "");
    const [toggle, setToggle] = useState(false);
    const [orderBy, setOrderBy] = useState(null);
    const [direc, setDirec] = useState(null);

    const navigate = useNavigate();

    const { pageNumber, pageChange, setPageNumber } = usePagination(
        setIsFetchingOrders,
        tableData?.ordersArchive?.page
    );

    const handleSearch = () => {
        let searchTerm = "";

        if (nomInput !== "") {
            searchTerm += "&lastName=" + nomInput;
        }

        if (objetInput !== "") {
            searchTerm += "&offerName[contains]=" + objetInput;
        }

        if (typeInput?.value) {
            searchTerm += "&orderType[is]=" + typeInput?.value;
        }

        if (gestInput?.value) {
            searchTerm += "&managerId[in][]=" + gestInput?.value;
        }

        if (deadline && deadline !== "") {
            if (typeof deadline === "string") {
                searchTerm += `&offerDeadline[before]=${format(
                    parseISO(deadline.slice(1, -1)),
                    "yyyy-MM-dd"
                )}&offerDeadline[after]=${format(parseISO(deadline.slice(1, -1)), "yyyy-MM-dd")}`;
            } else {
                searchTerm += `&offerDeadline[before]=${format(deadline, "yyyy-MM-dd")}&offerDeadline[after]=${format(
                    deadline,
                    "yyyy-MM-dd"
                )}`;
            }
            if (!orderBy || orderBy === "orderDate") {
                setDirec("desc");
                setOrderBy("orderDate");
            }
        }

        if (updateDate && updateDate !== "") {
            if (typeof updateDate === "string") {
                searchTerm += `&updatedAt[after]=${format(parseISO(updateDate.slice(1, -1)), "yyyy-MM-dd")}`;
            } else {
                searchTerm += `&updatedAt[after]=${format(updateDate, "yyyy-MM-dd")}`;
            }
            if (!orderBy || orderBy === "updatedAt") {
                setDirec("asc");
                setOrderBy("updatedAt");
            }
        }

        if (supplierInput !== "") {
            searchTerm += "&supplierId[in][]=" + supplierInput?.value;
        }

        setIsFetchingOrders(true);

        return searchTerm;
    };

    const refreshTable = () => {
        setNomInput("");
        setObjetInput("");
        setTypeInput("");
        setSupplierInput("");
        setGestInput("");
        setDeadline("");
        setUpdateDate("");
        setOrderBy(null);
        setDirec(null);
        setPageNumber(1);
        setIsFetchingOrders(true);
    };

    const handleOrderBy = (by) => {
        setOrderBy(by);
        setIsFetchingOrders(true);
        setDirec((prev) => (prev === "ASC" ? "DESC" : "ASC"));
    };

    useEffect(() => {
        if (isFetchingOrders) {
            const searchTerm = handleSearch();
            axiosGet(
                `orders?page=${pageNumber}&limit=15&groupedOrderStatus[is]=canceled${searchTerm}${
                    direc ? "&order=" + direc : ""
                }${orderBy ? "&order_by=" + orderBy : ""}`
            ).then((res) => {
                if (!isEqual(tableData?.ordersArchive?.data, res?.data?.orders))
                    setTableData({ ...tableData, ordersArchive: { data: res?.data?.orders, page: pageNumber } });
                setIsFetchingOrders(false);
            });
        }
    }, [isFetchingOrders]);

    useEffect(() => {
        if (!suppliers) {
            axiosGet(`suppliers`).then((res) => {
                setSuppliers(res?.data?.suppliers);
            });
        }
    }, []);

    useEffect(() => {
        if (!users) {
            axiosGet(`tenants`).then((res) => {
                setUsers(res?.data?.tenants);
            });
        }
    }, []);

    const getSuppliers = async (inputValue, callback = () => {}) => {
        if (inputValue.length > 2) {
            const res = await axiosGet(`suppliers?name=${inputValue}`);
            const data = res?.data?.suppliers.map((item) => ({ value: item?.id ?? 0, label: item?.name ?? "" }));
            callback(data);
        }
    };

    return (
        <section className="fd-container">
            <div className="fd-header">
                <BackwardTitle
                    title="Bons de commande"
                    subTitle="Liste des bons de commande archivés"
                    to={-1}
                    isBackward
                />
                <div className="fd-btns">
                    <Button color="primary" onClick={() => navigate("/bon-de-commande")}>
                        Actuel
                    </Button>
                </div>
            </div>
            <div className="fd-table">
                <TableHeader>
                    <TableHeaderTitle>Liste des bons de commande archivés</TableHeaderTitle>
                    {isFetchingOrders && (
                        <div className="table-newData-loader">
                            <LinearProgress />
                        </div>
                    )}
                    <TableHeaderActions>
                        <TableRefreshBtn onClick={refreshTable} />
                        <TableSearchBtn onClick={() => setToggle(!toggle)} open={toggle} />
                    </TableHeaderActions>
                </TableHeader>
                <TableSearch
                    onKeyDown={(e) => e.key === "Enter" && handleSearch() && setPageNumber(1)}
                    noAdvancedSearch
                    onSearch={() => {
                        handleSearch();
                        setPageNumber(1);
                    }}
                >
                    <TableSearchRow id="simple">
                        <TableSearchItem>
                            <InputSelect
                                placeholder="Type"
                                // invalid={city ? false : true}
                                name="type"
                                value={typeInput}
                                options={[
                                    { value: "Individual", label: "Individuel" },
                                    { value: "Grouped", label: "Groupé" },
                                ]}
                                onChange={(e) => setTypeInput(e ? e : null)}
                            ></InputSelect>
                        </TableSearchItem>
                        <TableSearchItem>
                            <Input
                                id="objet"
                                name="objet"
                                placeholder="Objet"
                                type="text"
                                onChange={(e) => setObjetInput(e.target.value)}
                                value={objetInput}
                            />
                        </TableSearchItem>
                        <TableSearchItem>
                            <InputSelect
                                placeholder="Gestionnaire"
                                // invalid={city ? false : true}
                                name="Gestionnaire"
                                value={gestInput}
                                options={users?.map((ele) => ({
                                    value: ele?.id,
                                    label: ele?.name,
                                }))}
                                onChange={(e) => setGestInput(e ? e : null)}
                            ></InputSelect>
                        </TableSearchItem>
                        <TableSearchItem>
                            <AsyncSelect
                                placeholder="Fournisseur"
                                isClearable
                                isSearchable
                                noOptionsMessage={() => ""}
                                value={supplierInput}
                                defaultOptions={
                                    suppliers
                                        ? Array.from(suppliers, (ele) => ({
                                              value: ele.id,
                                              label: ele.name,
                                          }))
                                        : []
                                }
                                loadOptions={getSuppliers}
                                onChange={(e) => setSupplierInput(e ? e : null)}
                            />
                            {/* <Input
                                id="supplier"
                                name="supplier"
                                placeholder="Fournisseur"
                                type="text"
                                onChange={(e) => setSupplierInput(e.target.value)}
                                value={supplierInput}
                            /> */}
                        </TableSearchItem>
                        <TableSearchItem>
                            <DatePicker
                                showYearDropdown
                                placeholderText="Date limite"
                                calendarMainColor="primary"
                                dateFormat="yyyy-MM-dd"
                                selected={
                                    deadline === "" || typeof deadline !== "string"
                                        ? deadline
                                        : parseISO(deadline.slice(1, -1))
                                }
                                onChange={(date) => setDeadline(date)}
                            ></DatePicker>
                        </TableSearchItem>
                        <TableSearchItem>
                            <DatePicker
                                showYearDropdown
                                placeholderText="Mis à jour"
                                calendarMainColor="primary"
                                dateFormat="yyyy-MM-dd"
                                selected={
                                    updateDate === "" || typeof updateDate !== "string"
                                        ? updateDate
                                        : parseISO(updateDate.slice(1, -1))
                                }
                                onChange={(date) => setUpdateDate(date)}
                            ></DatePicker>
                        </TableSearchItem>
                    </TableSearchRow>
                </TableSearch>
                <Table>
                    <thead>
                        <tr>
                            <Th onClick={() => handleOrderBy("id")}>N°</Th>
                            <Th onClick={() => handleOrderBy("orderType")}>Type</Th>
                            <Th onClick={() => handleOrderBy("offerName")}>Objet</Th>
                            <Th onClick={() => handleOrderBy("tenantId")}>Gestionnaire</Th>
                            <Th onClick={() => handleOrderBy("supplierId")}>Fournisseur</Th>
                            <Th onClick={() => handleOrderBy("orderDate")}>Date limite</Th>
                            <Th onClick={() => handleOrderBy("updatedAt")}>Mis à jour</Th>
                            <Th onClick={() => handleOrderBy("totalProducts")}>Nombre de produits</Th>
                            <Th onClick={() => handleOrderBy("orderedQuantity")}>Quantité commandée</Th>
                            <Th onClick={() => handleOrderBy("groupedOrderStatus")}>Statut</Th>
                        </tr>
                    </thead>
                    {isFetchingOrders && (
                        <TableLoader
                            loading={true}
                            columns={[
                                { type: "small" },
                                { type: "large" },
                                { type: "small" },
                                { type: "extra-small" },
                                { type: "extra-small" },
                                { type: "extra-small" },
                                { type: "extra-small" },
                                { type: "extra-small" },
                                { type: "extra-small" },
                                { type: "extra-small" },
                            ]}
                        />
                    )}
                    <tbody>
                        {!isFetchingOrders &&
                            tableData?.ordersArchive?.data &&
                            tableData?.ordersArchive?.data.map((ele) => (
                                <tr onClick={() => navigate(`/bon-de-commande/details/${ele.id}`)}>
                                    <th>{ele.id}</th>
                                    <td>{ele.orderType === "Grouped" ? "Groupé" : "Individuel"}</td>
                                    <td>{ele.offer?.name}</td>
                                    <td>{ele.manager?.name}</td>
                                    <td>{ele.supplier?.name}</td>
                                    <td>
                                        {ele?.offer?.deadline && format(parseISO(ele?.offer?.deadline), "yyyy-MM-dd")}
                                    </td>
                                    <td>{ele?.updatedAt && format(parseISO(ele?.updatedAt), "yyyy-MM-dd HH:mm")}</td>
                                    <td style={{ width: "10%" }}>{ele?.totalProducts}</td>
                                    <td style={{ width: "10%" }}>{ele?.orderedQuantity}</td>
                                    <td>
                                        {ele.groupedOrderStatus === "open" ? (
                                            <Tag className="tag-medium" bgColor="#6DD92A11" textColor="#6DD92A">
                                                Ouvert
                                            </Tag>
                                        ) : ele.groupedOrderStatus === "closed" ? (
                                            <Tag className="tag-medium" bgColor="#E8F5F4" textColor="#1BBAAC">
                                                Clôturé
                                            </Tag>
                                        ) : (
                                            <Tag className="tag-medium" bgColor="#F9EFEC" textColor="#F05A29">
                                                Annulé
                                            </Tag>
                                        )}
                                    </td>
                                    {/* <td>
                                        {ele.orderStatus === "En attente de confirmation" ? (
                                            <Tag className="tag-wide" bgColor="#FFF8EB" textColor="#F9AA00">
                                                En attente de confirmation
                                            </Tag>
                                        ) : ele.orderStatus === "En attente de traitement" ? (
                                            <Tag className="tag-wide" bgColor="#EDFAF9" textColor="#1BBAAC">
                                                En attente de traitement
                                            </Tag>
                                        ) : ele.orderStatus === "En cours de traitement" ? (
                                            <Tag className="tag-wide" bgColor="#F5F4ED" textColor="#FDA853">
                                                En cours de traitement
                                            </Tag>
                                        ) : ele.orderStatus === "Livrée" ? (
                                            <Tag className="tag-wide" bgColor="#E8F5F4" textColor="#1BBAAC">
                                                Livrée
                                            </Tag>
                                        ) : ele.orderStatus === "Reçue" ? (
                                            <Tag className="tag-wide" bgColor="#F5F4F9" textColor="#5D4BA7">
                                                Reçue
                                            </Tag>
                                        ) : ele.orderStatus === "Rejetée" ? (
                                            <Tag className="tag-wide" bgColor="#F9EFEC" textColor="#F05A29">
                                                Rejetée
                                            </Tag>
                                        ) : (
                                            <Tag className="tag-wide" bgColor="#F9EFEC" textColor="#F05A29">
                                                Annulée
                                            </Tag>
                                        )}
                                    </td> */}
                                </tr>
                            ))}

                        {tableData?.ordersArchive?.data?.length > 0 && (
                            <tr>
                                <td colSpan={10}>
                                    <div className="sob-table-pagination">
                                        <TablePreviousPage
                                            onClick={() => pageChange("prev")}
                                            disabled={pageNumber <= 1 || isFetchingOrders}
                                        />
                                        <TablePage>{pageNumber}</TablePage>
                                        <TableNextPage
                                            onClick={() => pageChange("next")}
                                            disabled={tableData?.ordersArchive?.data?.length < 15 || isFetchingOrders}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {!isFetchingOrders && tableData?.ordersArchive?.data?.length < 1 && (
                    <EmptyData title="Aucun résultat correspondant à votre recherche"></EmptyData>
                )}
            </div>
        </section>
    );
};

export default OrdersArchive;
