import React, { useContext } from "react";
import {
    Card,
    CardItem,
    CardItemTitle,
    CardItemValue,
    CardTitle,
    Col,
    DoctorCard,
    DoctorCardAvatar,
    DoctorCardInfosContainer,
    DoctorCardName,
    DoctorCardSpecialty,
    Row,
} from "@sobrus-com/sobrus-design-system";

import { UserContext } from "../../context/userContext";

function Profile() {
    const { user } = useContext(UserContext);

    return (
        <Card>
            <CardTitle>Profil</CardTitle>
            {/* <DoctorCard>
                <DoctorCardAvatar src={require("../../assets/doctor-icon.svg").default} />
                <DoctorCardInfosContainer>
                    <DoctorCardName>{user?.fullname}</DoctorCardName>
                    <DoctorCardSpecialty>{user?.email}</DoctorCardSpecialty>
                </DoctorCardInfosContainer>
            </DoctorCard> */}
            <Row>
                <Col>
                    <CardItem>
                        <CardItemTitle>Nom</CardItemTitle>
                        <CardItemValue>{user?.lastName || "-"}</CardItemValue>
                    </CardItem>
                    <CardItem>
                        <CardItemTitle>Tenant</CardItemTitle>
                        <CardItemValue>{user?.pharmacyName || "-"}</CardItemValue>
                    </CardItem>
                    <CardItem>
                        <CardItemTitle>Ville</CardItemTitle>
                        <CardItemValue>{user?.pharmacyCity?.name || "-"}</CardItemValue>
                    </CardItem>
                    <CardItem>
                        <CardItemTitle>Admin</CardItemTitle>
                        <CardItemValue>{user?.isAdmin ? "Oui" : "Non" || "-"}</CardItemValue>
                    </CardItem>
                </Col>
                <Col>
                    <CardItem>
                        <CardItemTitle>Prénom</CardItemTitle>
                        <CardItemValue>{user?.lastName || "-"}</CardItemValue>
                    </CardItem>
                    <CardItem>
                        <CardItemTitle>Adresse</CardItemTitle>
                        <CardItemValue>{user?.pharmacyAddress || "-"}</CardItemValue>
                    </CardItem>
                    <CardItem>
                        <CardItemTitle>Pays</CardItemTitle>
                        <CardItemValue>{user?.pharmacyCountry || "-"}</CardItemValue>
                    </CardItem>
                </Col>
            </Row>
        </Card>
    );
}

export default Profile;
