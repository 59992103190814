import { configureStore } from "@reduxjs/toolkit";

import tableDataReducer from "./features/tableDataSlice";
// import userReducer from "./features/userSlice";

export const store = configureStore({
    reducer: {
        // user: userReducer,
        tableData: tableDataReducer,
        // [authApi.reducerPath]: authApi.reducer,
    },
    // middleware: (getDefaultMiddleware) =>
    // getDefaultMiddleware({}).concat([authApi.middleware]),
    devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;
