import React from "react";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import {
    SettingsAccordion,
    SettingsAccordionItem,
    SettingsAccordionItemButton,
    SettingsAccordionItemHeading,
    SettingsAccordionPanel,
} from "@sobrus-com/sobrus-design-system";
import BackwardTitle from "components/backwardTitle/BackwardTitle";
// import { PASSWORD_URL } from 'env';

// import { useAcl } from '../../ACL/acl';

function Settings() {
    // const acl = useAcl();

    return (
        <section className="fd-container fade">
            <div className="fd-header">
                <BackwardTitle title="Mes paramètres" subTitle="Paramètres" />
            </div>
            <div className="settings-container">
                <SettingsAccordion className="settings-accord-container" preExpanded={["abcd"]}>
                    <SettingsAccordionItem uuid="abcd">
                        <SettingsAccordionItemHeading>
                            <SettingsAccordionItemButton>Mes paramètres</SettingsAccordionItemButton>
                        </SettingsAccordionItemHeading>
                        <SettingsAccordionPanel>
                            <NavLink
                                className={(navData) =>
                                    navData.isActive ? "sob-accordion__panel_item active" : "sob-accordion__panel_item"
                                }
                                to="/parametres/profil"
                            >
                                Profil
                            </NavLink>
                            {/* <a
                                className="sob-accordion__panel_item"
                                href={PASSWORD_URL}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Changer mon mot de passe
                            </a> */}
                        </SettingsAccordionPanel>
                    </SettingsAccordionItem>
                    {/* {acl?.setting.user.list && acl?.setting.user.connection_history && (
                        <SettingsAccordionItem>
                            <SettingsAccordionItemHeading>
                                <SettingsAccordionItemButton>Utilisateurs</SettingsAccordionItemButton>
                            </SettingsAccordionItemHeading>
                            <SettingsAccordionPanel>
                                {acl?.setting.user.list && (
                                    <NavLink
                                        className={(navData) =>
                                            navData.isActive
                                                ? 'sob-accordion__panel_item active'
                                                : 'sob-accordion__panel_item'
                                        }
                                        to="/parametres/utilisateurs"
                                    >
                                        Liste des utilisateurs
                                    </NavLink>
                                )}
                                {acl?.setting.user.connection_history && (
                                    <NavLink
                                        className={(navData) =>
                                            navData.isActive
                                                ? 'sob-accordion__panel_item active'
                                                : 'sob-accordion__panel_item'
                                        }
                                        to="/parametres/historique-connexions"
                                    >
                                        Historiques des connexions
                                    </NavLink>
                                )}
                            </SettingsAccordionPanel>
                        </SettingsAccordionItem>
                    )}
                    {acl?.demand?.listEmailAddress && acl?.demand?.listModalEmailAddress && (
                        <SettingsAccordionItem>
                            <SettingsAccordionItemHeading>
                                <SettingsAccordionItemButton>Paramètres d&apos;emails</SettingsAccordionItemButton>
                            </SettingsAccordionItemHeading>
                            <SettingsAccordionPanel>
                                {acl?.demand?.listEmailAddress && (
                                    <NavLink
                                        className={(navData) =>
                                            navData.isActive
                                                ? 'sob-accordion__panel_item active'
                                                : 'sob-accordion__panel_item'
                                        }
                                        to="/parametres/emails-entrants"
                                    >
                                        E-mails entrants
                                    </NavLink>
                                )}
                                {acl?.demand?.listModalEmailAddress && (
                                    <NavLink
                                        className={(navData) =>
                                            navData.isActive
                                                ? 'sob-accordion__panel_item active'
                                                : 'sob-accordion__panel_item'
                                        }
                                        to="/parametres/emails-sortants"
                                    >
                                        Modèles d&apos;E-mails
                                    </NavLink>
                                )}
                            </SettingsAccordionPanel>
                        </SettingsAccordionItem>
                    )}
                    {acl?.setting?.product?.range?.list && (
                        <SettingsAccordionItem>
                            <SettingsAccordionItemHeading>
                                <SettingsAccordionItemButton>Paramètres de produits</SettingsAccordionItemButton>
                            </SettingsAccordionItemHeading>
                            <SettingsAccordionPanel>
                                <NavLink
                                    className={(navData) =>
                                        navData.isActive
                                            ? 'sob-accordion__panel_item active'
                                            : 'sob-accordion__panel_item'
                                    }
                                    to="/parametres/gammes"
                                >
                                    Gammes de produits actives
                                </NavLink>
                            </SettingsAccordionPanel>
                        </SettingsAccordionItem>
                    )}
                    {acl?.setting.acl_roles.list && acl?.setting.acl_methods.list && (
                        <SettingsAccordionItem>
                            <SettingsAccordionItemHeading>
                                <SettingsAccordionItemButton>ACL</SettingsAccordionItemButton>
                            </SettingsAccordionItemHeading>
                            <SettingsAccordionPanel>
                                {acl?.setting.acl_roles.list && (
                                    <NavLink
                                        className={(navData) =>
                                            navData.isActive
                                                ? 'sob-accordion__panel_item active'
                                                : 'sob-accordion__panel_item'
                                        }
                                        to="/parametres/roles"
                                    >
                                        Rôles
                                    </NavLink>
                                )}
                                {acl?.setting.acl_methods.list && (
                                    <NavLink
                                        className={(navData) =>
                                            navData.isActive
                                                ? 'sob-accordion__panel_item active'
                                                : 'sob-accordion__panel_item'
                                        }
                                        to="/parametres/methodes"
                                    >
                                        Méthodes
                                    </NavLink>
                                )}
                            </SettingsAccordionPanel>
                        </SettingsAccordionItem>
                    )}
                    <SettingsAccordionItem>
                        <SettingsAccordionItemHeading>
                            <SettingsAccordionItemButton>Paramêtres d&apos;emails</SettingsAccordionItemButton>
                        </SettingsAccordionItemHeading>
                        <SettingsAccordionPanel>
                            <NavLink
                                className={(navData) =>
                                    navData.isActive ? 'sob-accordion__panel_item active' : 'sob-accordion__panel_item'
                                }
                                to="/parametres/rapportPublication"
                            >
                                Rapport des publications
                            </NavLink>
                            <NavLink
                                className={(navData) =>
                                    navData.isActive ? 'sob-accordion__panel_item active' : 'sob-accordion__panel_item'
                                }
                                to="/parametres/rapportMarketplace"
                            >
                                Rapport de la marketplace
                            </NavLink>
                        </SettingsAccordionPanel>
                    </SettingsAccordionItem> */}
                </SettingsAccordion>
                <div className="settings-content">
                    <Outlet />
                </div>
            </div>
        </section>
    );
}

export default Settings;
