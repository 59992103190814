import React from "react";
import {
    Navbar,
    Collapse,
    Nav as N,
    NavItem,
    NavLink,
    NavItemMore,
    NavItemMoreBtn,
    NavItemMoreSubMenu,
    NavUserProfile,
    DropdownItem,
    NavProfileLink,
    NavLogoutLink,
    NavbarBrand,
    MegaNavBar,
    MegaNavBarLogo,
    MegaNavBarItems,
    MegaNavBarItem,
    MegaNavUser,
    MegaNavUserInfo,
    MegaNavUserName,
    MegaNavUserMenu,
    MegaNavUserProfile,
    MegaNavUserLogout,
    MegaNavBarItemLink,
} from "@sobrus-com/sobrus-design-system";
import { NavLink as RouteLink } from "react-router-dom";
import { axiosGet } from "../api/API";
import { useNavigate } from "react-router";
import axios from "axios";
import { API_URL2 } from "../env";

const Nav = ({ user }) => {
    const navigate = useNavigate();

    const logOut = () => {
        // fetch("https://api.fidny.sobrus.ovh/auth/revoke", { credentials: "include" }).then((res) => navigate(0));
        axiosGet("/auth/revoke", null, API_URL2).then((res) => {
            console.log(res?.response?.data);
            navigate(0);
        });
    };

    return (
        <div
            style={{
                backgroundColor: "#F5F5F5",
                width: "100%",
            }}
        >
            <MegaNavBar size="large">
                <MegaNavBarLogo onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                    <img
                        src={require("../assets/logo.svg").default}
                        style={{
                            height: 40,
                            maxWidth: 250,
                            display: "block",
                        }}
                        alt=""
                    />
                </MegaNavBarLogo>
                <MegaNavBarItems>
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/" title="Tableau de bord">
                            Tableau de bord
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/produits" title="Produits">
                            Produits
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/fournisseurs" title="Fournisseurs">
                            Fournisseurs
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/contacts" title="Contacts">
                            Contacts
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/bon-de-commande" title="Bons de commande">
                            Bons de commande
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/offres" title="Offres">
                            Offres
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/evenements" title="Événements">
                            Événements
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/groupes" title="Groupes">
                            Groupes
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/rapport-balance" title="Rapports">
                            Rapports
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/parametres" title="Paramètres">
                            Paramètres
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                </MegaNavBarItems>
                <MegaNavUser iconText={`${user?.firstName[0]}${user?.lastName[0]}`}>
                    <MegaNavUserInfo>
                        <MegaNavUserName>
                            {user?.firstName} {user?.lastName}
                        </MegaNavUserName>
                        {/* <MegaNavUserEmail>osefiani@sobrus.com</MegaNavUserEmail> */}
                    </MegaNavUserInfo>
                    <MegaNavUserMenu>
                        <MegaNavUserProfile onClick={() => navigate("/parametres/profil")} />
                        <MegaNavUserLogout onClick={() => logOut()} />
                    </MegaNavUserMenu>
                </MegaNavUser>
            </MegaNavBar>
        </div>
    );
};

export default Nav;
