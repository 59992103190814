import { Button } from "@sobrus-com/sobrus-design-system";
import React from "react";
import { useNavigate } from "react-router";
// import useTitleChanger from "../utils/useTitleChanger";

const Page404 = () => {
    const navigate = useNavigate();
    // useTitleChanger("Sobrus - 404");

    return (
        <div className="page404-container">
            <h2 className="h2">
                <strong>ERROR 404</strong>
                <br />
                CETTE PAGE N'EXISTE PAS !
            </h2>
            <img src={require("assets/error-doc.svg").default} alt="error 404" />
            <Button onClick={() => navigate("/")} className="btn">
                Revenir à l'accueil
            </Button>
        </div>
    );
};

export default Page404;
