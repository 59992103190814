import { createSlice } from "@reduxjs/toolkit";

// type DataState = {
//     data: {
//         [key: string]: { data: []; loading: boolean; error: string; loadingNewData: boolean; noAnimation: boolean };
//     };
// };

const initialState = {
    data: {},
};

// const keyValue = ()

const tableData = createSlice({
    name: "tableData",
    initialState,
    reducers: {
        createStorageName: (state, action) => {
            state.data = {
                ...state.data,
                [action.payload]: { data: [], loading: true, loadingNewData: false, noAnimation: false, error: "" },
            };
        },
        loadNewData: (state, action) => {
            state.data[action.payload].loadingNewData = true;
        },
        isLoading: (state, action) => {
            state.data[action.payload].loading = true;
        },
        setData: (state, action) => {
            const [key] = Object.keys(action.payload);
            const [value] = Object.values(action.payload);
            state.data[key].loading = false;
            state.data[key].loadingNewData = false;
            state.data[key].data = value;
            state.data[key].noAnimation = true;
        },

        setLoading: (state, action) => {
            state.data[action.payload].loading = false;
            state.data[action.payload].loadingNewData = false;
        },
    },
});

export const { setData, setLoading, createStorageName, loadNewData, isLoading } = tableData.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default tableData.reducer;
