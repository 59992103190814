import React from "react";
import { BrowserRouter } from "react-router-dom";
import HomeNavigator from "./HomeNavigator";
import { Provider } from "react-redux";
import "../styles/styles.scss";
import { DataContextProvider } from "context/dataContext";
import { store } from "redux/store";
import { RapportBContextProvider } from "context/rapportBContext";

const MainNavigator = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <DataContextProvider>
                    <RapportBContextProvider>
                        <HomeNavigator />
                    </RapportBContextProvider>
                </DataContextProvider>
            </BrowserRouter>
        </Provider>
    );
};

export default MainNavigator;
