import { Button, SupportBtn } from "@sobrus-com/sobrus-design-system";
import React, { useContext } from "react";
import { Navigate } from "react-router";
import { UserContext } from "../context/userContext";
import { AUTH_URL } from "../env";

const Signin = () => {
    const { isLogin } = useContext(UserContext);

    console.log(isLogin);

    if (!isLogin) {
        return (
            <section className="signin-wrapper">
                <header className="signin-header">
                    <img src={require("../assets/logo.svg").default} alt="" />
                    <SupportBtn
                        data={{
                            clientRef: "ma-3948-35523-rr",
                            email: "support@sobrus.com",
                            phoneNumber: "05 30 500 500",
                            showEmail: true,
                        }}
                        position="bottom right"
                    />
                </header>
                <div className="signin-container">
                    <img src={require("../assets/logo.svg").default} alt="" />
                    <a href={AUTH_URL}>
                        <Button href="" color="primary">
                            Se connecter
                        </Button>
                    </a>
                </div>
            </section>
        );
    } else return <Navigate to="/" />;
};

export default Signin;
