import React from "react";
import { useNavigate } from "react-router-dom";
import "./BackwardTitle.scss";

const BackwardTitle = ({ title, subTitle, to, isBackward = false, onClick }) => {
    const navigate = useNavigate();

    return (
        <div className="backward-container">
            {isBackward && (
                <div onClick={onClick ? onClick : () => navigate(to, { replace: true })}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 64 62">
                        <g id="Group_2282" data-name="Group 2282" transform="translate(-1385 -313)">
                            <rect
                                id="Rectangle_2"
                                data-name="Rectangle 2"
                                width="64"
                                height="62"
                                rx="16"
                                transform="translate(1385 313)"
                                fill="rgba(185,185,185,0.08)"
                            />
                            <path
                                id="Icon_ionic-ios-arrow-forward"
                                data-name="Icon ionic-ios-arrow-forward"
                                d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z"
                                transform="translate(1434.997 361.508) rotate(180)"
                                fill="#9d9d9d"
                            />
                        </g>
                    </svg>
                </div>
            )}
            <div className="backward-content">
                <h3>{title}</h3>
                <h6>{subTitle}</h6>
            </div>
        </div>
    );
};

export default BackwardTitle;
