import axios from "axios";
import { API_URL } from "../env";

export const axiosGet = async (suffix, params, API_URL2) => {
    try {
        const response = await axios.get(API_URL2 ? API_URL2 + suffix : API_URL + suffix, {
            withCredentials: true,
            params: params,
        });
        console.log(response.data);
        const data = await response;
        return data;
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401 && suffix !== "users/authenticated-user") {
            window.location.reload();
        }
        return error;
    }
};

export const axiosPost = async (suffix, body, headers) => {
    // const response = await axios.post(API_URL + suffix, body, { withCredentials: true, headers: headers });
    // if (!headers) {
    //     const data = await response.json();
    //     return data;
    // } else return response;
    try {
        const response = await axios.post(API_URL + suffix, body, { withCredentials: true, headers: headers });
        if (!headers) {
            const data = await response;
            return data;
        } else return response;
    } catch (error) {
        console.error(error);
        if (error?.response?.status === 401) {
            window.location.reload();
        }
        return error;
        // if (error.response?.data?.message === "token expiré") {
        //     localStorage.removeItem("isLogin");
        //     window.location.reload();
        // }
    }
};

export const axiosPatch = async (suffix, body, headers) => {
    try {
        const response = await axios.patch(API_URL + suffix, body, { withCredentials: true, headers: headers });
        if (!headers) {
            const data = await response;
            return data;
        } else return response;
    } catch (error) {
        console.error(error);
        return error;
        // if (error.response?.data?.message === "token expiré") {
        //     localStorage.removeItem("isLogin");
        //     window.location.reload();
        // }
    }
};

export const axiosDelete = async (suffix, body, headers) => {
    try {
        const response = await axios.delete(API_URL + suffix, { withCredentials: true, headers });
        if (!headers) {
            const data = await response;
            return data;
        }
        return response;
    } catch (error) {
        console.error(error);
        if (error.response?.data?.message === "token expiré") {
            localStorage.removeItem("isLogin");
            window.location.reload();
        }
        return error;
    }
};
