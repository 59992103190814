import { SpinnerLoading } from "@sobrus-com/sobrus-design-system";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/userContext";

// export const PrivateRoute = ({ component }) => {
//     const { isLogin } = useContext(UserContext);

//     return isLogin ? component : <Navigate to="/signin" />;
// };

export function PrivateRoute({ component, acl }) {
    const { isLogin, isFetching } = useContext(UserContext);

    if (!isFetching) {
        if (isLogin) {
            if (acl) {
                return component;
            }
            return <Navigate to="/access-denied" />;
        }
        return <Navigate to="/signin" />;
    }
    return (
        <div className="labs">
            <div className="loader">
                <SpinnerLoading color="#d11c33" loading />
            </div>
        </div>
    );
}